import React from 'react'
import Slider from '@fil1pe/react-slider'
import { Button } from '~/components'
import * as st from '~/assets/styl/Carousel.module.styl'
import cn from 'classnames'

type Props = {
  children: React.ReactNode
  className?: string
  home?: boolean
}

const Carousel = ({ children, className, home }: Props) => (
  <Slider
    className={cn(st.wide, className, home && st.home)}
    renderArrow={({ onClick }) => (
      <Button onClick={onClick} className={st.arrow} type="arrow"></Button>
    )}
  >
    {children}
  </Slider>
)

export default Carousel

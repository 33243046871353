import React from 'react'
import { Link } from 'gatsby'
import * as st from '~/assets/styl/Widget.module.styl'

type Props = {
  whatsappLabel?: string
  contactLabel?: string
}

export const WidgetContent = ({
  whatsappLabel = 'Chame no Whatsapp',
  contactLabel = 'Envie uma mensagem',
}: Props) => (
  <>
    <a
      href="https://api.whatsapp.com/send?phone=5548991689569"
      target="_blank"
      rel="noreferrer"
    >
      <span className="icon-whatsapp"></span>
      {whatsappLabel}
    </a>
    <a href="tel:4833817000" target="_blank" rel="noreferrer">
      <span className="icon-phone"></span>
      48 3381-7000
    </a>
    <Link to="/contato/">
      <span className="icon-mail"></span>
      {contactLabel}
    </Link>
  </>
)

const Widget = () => (
  <div className={st.core}>
    <WidgetContent />
  </div>
)

export default Widget

import React from 'react'
import { Form, Button } from '~/components'
import { Context as EnterprisesContext } from '~/pages/_empreendimentos'
import { useApi } from '~/siteApi'
//import { Link } from 'gatsby'
import * as st from '~/assets/styl/Contact.module.styl'
import * as st1 from '~/assets/styl/Form.module.styl'

const RateAttendance = () => {
  const { enterprises }: EnterprisesContext = useApi({}, 'page-enterprise')

  return (
    <div className={st.subpage}>
      <div className={st.header}>
        <h3>Avalie seu atendimento</h3>
        <p>
          Este formulário tem como objetivo avaliar a qualidade do atendimento
          prestado pelo seu corretor. As informações aqui registradas serão
          utilizadas para o aprimoramento de nossos serviços, preservando a sua
          identidade e possibilitando que você expresse sua opinião, elogie ou
          sugira melhorias de forma segura.
        </p>
      </div>

      <h4>Envie uma mensagem</h4>
      <Form
        path="page-rate"
        idPrefix="rate-"
        className={st1.form}
        inputs={[
          {
            name: 'name',
            label: 'Nome',
            placeholder: 'Qual seu nome?',
            className: st1.medium,
          },
          {
            name: 'email',
            label: 'E-mail',
            type: 'email',
            placeholder: 'Qual seu e-mail?',
            className: st1.small,
          },
          {
            name: 'phone',
            label: 'Telefone',
            mask: 'phone',
            placeholder: '(DD) + Número',
            className: st1.small,
          },
          {
            name: 'enterprise',
            label: 'Empreendimento',
            type: 'select',
            placeholder: 'Selecione',
            options: (enterprises || []).map(({ name }) => ({
              name,
            })),
            className: st1.medium,
          },
          {
            name: 'realEstate',
            label: 'Imobiliária',
            placeholder: 'Qual é o nome da imobiliária?',
            className: st1.medium,
          },
          {
            name: 'brokerName',
            label: 'Corretor',
            placeholder: 'Qual é o nome do corretor?',
            className: st1.medium,
          },
          {
            name: 'brokerRespond',
            label: 'O corretor deu retorno à sua solicitação?',
            type: 'select',
            placeholder: 'Selecione',
            options: [{ name: 'Sim' }, { name: 'Não' }],
            className: st1.medium,
          },
          {
            name: 'brokerTime',
            label: 'Qual o tempo de retorno do corretor?',
            type: 'select',
            placeholder: 'Selecione',
            options: [
              { name: '1 dia' },
              { name: '2 dias' },
              { name: '3 dias' },
              { name: '4 dias' },
              { name: '5 ou mais dias' },
            ],
            className: st1.medium,
          },
          {
            name: 'brokerDoubt',
            label: 'Suas dúvidas foram sanadas em sua totalidade?',
            type: 'select',
            placeholder: 'Selecione',
            options: [{ name: 'Sim' }, { name: 'Não' }],
            className: st1.medium,
          },
          {
            name: 'brokerNote',
            label:
              'Quanto ao atendimento recebido pelo corretor, você avalia como?',
            type: 'select',
            placeholder: 'Selecione',
            options: [{ name: 'Bom' }, { name: 'Regular' }, { name: 'Ruim' }],
            className: st1.medium,
          },
          {
            name: 'brokerContact',
            label: 'Deseja que outro corretor entre em contato?',
            type: 'select',
            placeholder: 'Selecione',
            options: [{ name: 'Sim' }, { name: 'Não' }],
            className: st1.medium,
          },
          {
            name: 'message',
            label: 'Críticas ou sugestões',
            type: 'textarea',
            placeholder: 'Deixe aqui sua crítica ou sugestão',
            className: st1.wide,
          },
          /*{
          name: 'privacy',
          label: (
            <>
              Declaro que li e aceito a{' '}
              <Link to="/politica-de-privacidade/">
                Política de Privacidade
              </Link>
            </>
          ),
          type: 'checkbox',
          className: st1.privacy,
        },*/
        ]}
        button={<Button>Enviar</Button>}
        loadingButton={<Button disabled>Enviando...</Button>}
      />
    </div>
  )
}

export default RateAttendance

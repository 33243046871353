import React from 'react'
import { Link } from 'gatsby'
import { Button, LazyLoad } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/Releases.module.styl'

type Props = {
  items: Array<{
    imageFeatureDesktop: string
    imageFeatureMobile: string
    name: string
    slug: string
    metreage: string
    tag: string
    neighborhood: string
    city: string
    state: string
  }>
  className?: string
}

const Releases = ({ items, className }: Props) => (
  <section className={cn(st.core, className)}>
    <div className="container">
      <h3>Lançamentos</h3>
      <ul>
        {items.map(
          (
            {
              name,
              slug,
              metreage,
              neighborhood,
              city,
              state,
              imageFeatureDesktop,
              imageFeatureMobile,
            },
            key
          ) => (
            <LazyLoad
              key={key}
              src={imageFeatureDesktop}
              mobileSrc={imageFeatureMobile}
              type="li"
            >
              <Link to={'/empreendimento/' + slug + '/'}>
                <div className={st.info}>
                  {[metreage].map((tag, key) => (
                    <span key={key} className={st.tag}>
                      {tag}
                    </span>
                  ))}
                  <h4>{name}</h4>
                  <p className={st.location}>
                    {neighborhood} - {city} - {state}
                  </p>
                  <Button type="small" className={st.btn}>
                    Saiba mais
                  </Button>
                </div>
              </Link>
            </LazyLoad>
          )
        )}
      </ul>
    </div>
  </section>
)

export default Releases

import React, { useState } from 'react'
import { Button, Carousel, LazyLoad } from '~/components'
import { Link } from 'gatsby'
import cn from 'classnames'
import * as st from '~/assets/styl/Lots.module.styl'

type Props = {
  items: Array<{
    imageFeatureDesktop: string
    imageFeatureMobile: string
    name: string
    slug: string
    metreage: string
    tag: string
    neighborhood: string
    city: string
    state: string
  }>
  slider?: boolean
  className?: string
  id?: string
}

const Lots = ({ items, slider, className, id }: Props) => {
  const [loading /*, setLoading*/] = useState(false)
  const [loadCount, setLoadCount] = useState(6)
  const loadMore = () => {
    //setLoading(true)
    setLoadCount(loadCount + 6)
  }

  return !slider ? (
    <section className={cn(st.core, className)} id={id}>
      <div className="container">
        <h3>Terrenos, loteamentos e lotes</h3>
        <p>Conheça os empreendimentos da Alínea</p>
        <div className={st.wrapper}>
          <ul>
            {items
              .slice(0, loadCount)
              .map(
                (
                  {
                    name,
                    slug,
                    metreage,
                    tag,
                    neighborhood,
                    city,
                    state,
                    imageFeatureDesktop,
                    imageFeatureMobile,
                  },
                  key
                ) => (
                  <li key={key}>
                    <Link
                      to={'/empreendimento/' + slug + '/'}
                      className={st.item}
                    >
                      <LazyLoad
                        className={st.thumbnail}
                        src={imageFeatureDesktop}
                        mobileSrc={imageFeatureMobile}
                      />
                      <div className={st.tags}>
                        {[metreage].map((tag, key) => (
                          <span key={key}>{tag}</span>
                        ))}
                      </div>
                      <h4>{name}</h4>
                      <p className={st.location}>
                        {neighborhood} - {city} - {state}
                      </p>
                      {tag && <span className={st.highlight}>{tag}</span>}
                    </Link>
                  </li>
                )
              )}
          </ul>
          {loadCount < items.length && (
            <div className={st.btnWrapper}>
              <Button
                type="outlined small arrowBottom"
                onClick={loadMore}
                disabled={loading}
              >
                {loading ? 'Carregando...' : 'Carregar mais'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </section>
  ) : (
    <Carousel className={cn(st.slider, className)}>
      {items.map(
        (
          {
            name,
            slug,
            metreage,
            tag,
            neighborhood,
            city,
            state,
            imageFeatureDesktop,
            imageFeatureMobile,
          },
          key
        ) => (
          <Link
            to={'/empreendimento/' + slug + '/'}
            className={st.item}
            key={key}
          >
            <LazyLoad
              className={st.thumbnail}
              src={imageFeatureDesktop}
              mobileSrc={imageFeatureMobile}
            />
            <div className={st.tags}>
              {[metreage].map((tag, key) => (
                <span key={key}>{tag}</span>
              ))}
            </div>
            <h4>{name}</h4>
            <p className={st.location}>
              {neighborhood} - {city} - {state}
            </p>
            {tag && <span className={st.highlight}>{tag}</span>}
          </Link>
        )
      )}
    </Carousel>
  )
}

export default Lots

// extracted by mini-css-extract-plugin
export var core = "Footer-module--core--NA5Wr";
export var icon = "Footer-module--icon--S+qS+";
export var location = "Footer-module--location--ofFpt";
export var nav = "Footer-module--nav--fTXBV";
export var newsletter = "Footer-module--newsletter--w6JxO";
export var policy = "Footer-module--policy--ZuEeM";
export var policylink = "Footer-module--policylink--vOmdc";
export var privacy = "Footer-module--privacy--vXF2p";
export var sales = "Footer-module--sales--T-vlq";
export var socials = "Footer-module--socials--pWcv+";
export var tikiBlock = "Footer-module--tiki-block--2F61T";
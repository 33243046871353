import React from 'react'
import { WidgetContent } from './Widget'
import cn from 'classnames'
import * as st from '~/assets/styl/Widget.module.styl'

const Widget = () => (
  <div className={cn(st.core, st.mobile)}>
    <WidgetContent whatsappLabel="Whatsapp" contactLabel="Fale conosco" />
  </div>
)

export default Widget

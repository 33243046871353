// extracted by mini-css-extract-plugin
export var arrow = "Button-module--arrow---55x0";
export var arrowBottom = "Button-module--arrow-bottom--R-KfI";
export var core = "Button-module--core--N3pUV";
export var icon = "Button-module--icon--ikLz+";
export var newsletterButton = "Button-module--newsletter-button--QqirG";
export var noArrow = "Button-module--no-arrow--1Iz7g";
export var outlined = "Button-module--outlined--h3xfH";
export var small = "Button-module--small--6eTip";
export var tiny = "Button-module--tiny--PD-JC";
export var white = "Button-module--white--EXN+z";
exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-atendimento-tsx": () => import("./../../../src/pages/atendimento.tsx" /* webpackChunkName: "component---src-pages-atendimento-tsx" */),
  "component---src-pages-avalie-seu-atendimento-tsx": () => import("./../../../src/pages/avalie-seu-atendimento.tsx" /* webpackChunkName: "component---src-pages-avalie-seu-atendimento-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-empreendimento-tsx": () => import("./../../../src/pages/_empreendimento.tsx" /* webpackChunkName: "component---src-pages-empreendimento-tsx" */),
  "component---src-pages-empreendimentos-tsx": () => import("./../../../src/pages/_empreendimentos.tsx" /* webpackChunkName: "component---src-pages-empreendimentos-tsx" */),
  "component---src-pages-empresa-tsx": () => import("./../../../src/pages/_empresa.tsx" /* webpackChunkName: "component---src-pages-empresa-tsx" */),
  "component---src-pages-fale-com-o-presidente-tsx": () => import("./../../../src/pages/fale-com-o-presidente.tsx" /* webpackChunkName: "component---src-pages-fale-com-o-presidente-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/_index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ofereca-sua-area-tsx": () => import("./../../../src/pages/ofereca-sua-area.tsx" /* webpackChunkName: "component---src-pages-ofereca-sua-area-tsx" */),
  "component---src-pages-politica-de-privacidade-tsx": () => import("./../../../src/pages/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-tsx" */),
  "component---src-pages-portal-do-cliente-tsx": () => import("./../../../src/pages/_portal-do-cliente.tsx" /* webpackChunkName: "component---src-pages-portal-do-cliente-tsx" */),
  "component---src-pages-seja-um-corretor-tsx": () => import("./../../../src/pages/seja-um-corretor.tsx" /* webpackChunkName: "component---src-pages-seja-um-corretor-tsx" */),
  "component---src-pages-seja-um-parceiro-tsx": () => import("./../../../src/pages/seja-um-parceiro.tsx" /* webpackChunkName: "component---src-pages-seja-um-parceiro-tsx" */),
  "component---src-pages-sucesso-tsx": () => import("./../../../src/pages/sucesso.tsx" /* webpackChunkName: "component---src-pages-sucesso-tsx" */)
}


import React from 'react'
import ReactLightbox from 'lightbox-react'
import 'lightbox-react/style.css'

type Props = {
  index: number
  images?: Array<string>
  setIndex: React.Dispatch<React.SetStateAction<number>>
  setImages: React.Dispatch<React.SetStateAction<Array<string>>>
  legends?: Array<string>
  setLegends?: React.Dispatch<React.SetStateAction<Array<string>>>
}

const Lightbox = ({
  images,
  index,
  setIndex,
  setImages,
  legends,
  setLegends,
}: Props) => (
  <>
    {images && (
      <ReactLightbox
        mainSrc={images[index]}
        onCloseRequest={() => {
          setImages(null)
          setIndex(0)
          setLegends?.(null)
        }}
        prevSrc={images[(index + images.length - 1) % images.length]}
        nextSrc={images[(index + 1) % images.length]}
        onMovePrevRequest={() =>
          setIndex((index + images.length - 1) % images.length)
        }
        onMoveNextRequest={() => setIndex((index + 1) % images.length)}
        imageCaption={legends?.[index]}
      />
    )}
  </>
)

export default Lightbox

import React, { createContext, useEffect, useState } from 'react'
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions'
import { PageProps } from 'gatsby'
import { Nav, Widget, WidgetMobile, Loading } from '~/components'

export const context = createContext<{
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}>({
  loading: false,
  setLoading: () => null,
})

const layout = (loadingDefault: boolean, { children, location }: PageProps) => {
  const [loading, setLoading] = useState(loadingDefault)
  const [enterOpacity, setEnterOpacity] = useState(0.9999)
  useEffect(() => {
    setEnterOpacity(0)
  }, [])

  return (
    <>
      <Nav location={location} />
      {loading && <Loading />}
      <context.Provider value={{ loading, setLoading }}>
        {location.pathname === '/' && <Widget />}
        <WidgetMobile />
        <TransitionProvider
          location={location}
          mode="immediate"
          enter={{
            opacity: enterOpacity,
          }}
          usual={{
            opacity: 1,
          }}
          leave={{
            opacity: 0,
            config: {
              duration: 200,
            },
          }}
        >
          <TransitionViews>{children}</TransitionViews>
        </TransitionProvider>
      </context.Provider>
    </>
  )
}

export default (props: PageProps) => {
  const context = props.pageContext as { [key: string]: never }
  if (context?.layout === '404') return layout(true, props)
  return layout(false, props)
}

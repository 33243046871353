// Aqui os dados do head das páginas

import React from 'react'
import Helmet from 'react-helmet'
import Opengraph from '~/assets/img/Opengraph.jpg'

interface Props {
  title?: string
  description?: string
  location?: Location
}

const DEFAULT_TITLE = process.env.GATSBY_SITE_NAME
const DEFAULT_DESCRIPTION =
  'Localizada na cidade de São José, na Grande Florianópolis, a Alínea Urbanismo trabalha desde 1991 transformando mercados e levando, para as regiões de seus empreendimentos, urbanização e uma nova dinâmica.'

const Head = ({ title, description, location }: Props) => (
  <Helmet>
    <meta
      property="og:image"
      itemProp="image"
      content={`https://${process.env.GATSBY_SITE_URL}${Opengraph}`}
    />
    <meta
      name="twitter:image"
      content={`https://${process.env.GATSBY_SITE_URL}${Opengraph}`}
    />
    <title>{title || DEFAULT_TITLE}</title>
    <meta property="og:title" content={title || DEFAULT_TITLE} />
    <meta property="og:site_name" content={process.env.GATSBY_SITE_NAME} />
    <meta name="description" content={description || DEFAULT_DESCRIPTION} />
    <meta
      property="og:description"
      content={description || DEFAULT_DESCRIPTION}
    />
    <link
      rel="canonical"
      href={`https://${process.env.GATSBY_SITE_URL}${location?.pathname || ''}`}
    />
    <meta
      property="og:url"
      content={`https://${process.env.GATSBY_SITE_URL}${
        location?.pathname || ''
      }`}
    />
    <link rel="icon" type="image/png" href="/favicon.png" />
    <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
    <meta property="og:locale" content="pt_BR" />
    <meta charSet="utf-8" />
    <meta
      name="viewport"
      content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, target-densitydpi=device-dpi"
    />
  </Helmet>
)

export default Head

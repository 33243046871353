export default [
  {
    name: 'Instagram',
    title: 'Siga no Instagram',
    icon: 'instagram',
    url: 'https://www.instagram.com/alineaurbanismo/',
  },
  {
    name: 'Facebook',
    title: 'Siga no Facebook',
    icon: 'facebook',
    url: 'https://www.facebook.com/alineaurbanismo/',
  },
  {
    name: 'LinkedIn',
    title: 'Siga no LinkedIn',
    icon: 'linkedin',
    url: 'https://www.linkedin.com/company/j-a-urbanismo/',
  },
  {
    name: 'YouTube',
    title: 'Inscreva-se no YouTube',
    icon: 'youtube',
    url: 'https://www.youtube.com/channel/UCBBwRG37LIwpgPfYorNkIFg',
  },
  {
    name: 'Blog',
    title: 'Acompanhe no Blog',
    icon: 'wordpress',
    url: 'https://blog.alineaurbanismo.com.br',
  },
]

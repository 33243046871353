import React from 'react'
import { Button } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/Sales.module.styl'

type Props = {
  className?: string
}

const Sales = ({ className }: Props) => (
  <ul className={cn(st.core, className)}>
    <li>
      <Button to="tel:4833817000" external type="noArrow" className="btn">
        <div>
          <span>SC</span>48 3381-7000
        </div>
      </Button>
    </li>
    <li>
      <Button to="tel:08007355500" external type="noArrow" className="btn">
        <div>
          <span>MS</span>0800 735 5500
        </div>
      </Button>
    </li>
    <li>
      <Button
        to="https://api.whatsapp.com/send?phone=5548991689569"
        external
        type="noArrow"
        className="btn whatsapp"
      >
        <div>
          <span className="icon-whatsapp"></span>WhatsApp
        </div>
      </Button>
    </li>
  </ul>
)

export default Sales

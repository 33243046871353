import React from 'react'
import { Form, Button } from '~/components'
import { states } from '~/data'
//import { Link } from 'gatsby'
import * as st from '~/assets/styl/Contact.module.styl'
import * as st1 from '~/assets/styl/Form.module.styl'

const TalkToPresident = () => (
  <div className={st.subpage}>
    <div className={st.header}>
      <h3>Fale com o presidente</h3>
      <p>
        Seu contato não foi solucionado?
        <br />
        Utilize este canal exclusivo.
      </p>
    </div>

    <h4>Envie uma mensagem</h4>
    <Form
      path="page-president"
      idPrefix="contact-president"
      className={st1.form}
      inputs={[
        {
          name: 'name',
          label: 'Nome',
          placeholder: 'Qual seu nome?',
          className: st1.medium,
        },
        {
          name: 'email',
          label: 'E-mail',
          type: 'email',
          placeholder: 'Qual seu e-mail?',
          className: st1.small,
        },
        {
          name: 'document',
          label: 'CPF',
          mask: 'cpf',
          placeholder: 'Digite seu CPF',
          className: st1.small,
        },
        {
          name: 'state',
          label: 'Estado',
          type: 'select',
          placeholder: 'Selecione',
          options: states.map((s) => ({ name: s.name })),
          className: st1.small,
        },
        {
          name: 'city',
          label: 'Cidade',
          className: st1.small,
        },
        {
          name: 'phone',
          label: 'Telefone',
          mask: 'phone',
          placeholder: '(DD) + Número',
          className: st1.small,
        },
        {
          name: 'protocol',
          label: 'Protocolo de atendimento',
          placeholder: 'Ex: AL9003990',
          className: st1.small,
        },
        {
          name: 'message',
          label: 'Mensagem',
          type: 'textarea',
          placeholder: 'Deixe aqui sua mensagem, comentário ou reclamação',
          className: st1.wide,
        },
        /*{
          name: 'privacy',
          label: (
            <>
              Declaro que li e aceito a{' '}
              <Link to="/politica-de-privacidade/">
                Política de Privacidade
              </Link>
            </>
          ),
          type: 'checkbox',
          className: st1.privacy,
        },*/
      ]}
      button={<Button>Enviar</Button>}
      loadingButton={<Button disabled>Enviando...</Button>}
    />
  </div>
)

export default TalkToPresident

import { useState, useEffect } from 'react'
import { blogApi } from '.'

const useBlogApi = (defaultValue, path) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    blogApi('get', path)
      .then((posts) =>
        posts.map(
          ({ id, title: { rendered: title }, date, _embedded, link }) => ({
            id,
            date: Date(date),
            title,
            thumbnail:
              (_embedded['wp:featuredmedia'] &&
                _embedded['wp:featuredmedia'][0] &&
                _embedded['wp:featuredmedia'][0].source_url) ||
              '',
            link,
          })
        )
      )
      .then((posts) => setValue(posts))
      .catch((err) => console.error(err))
  }, [])

  return value
}

export default useBlogApi

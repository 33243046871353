// extracted by mini-css-extract-plugin
export var close = "Nav-module--close--cijva";
export var core = "Nav-module--core--rWT0w";
export var hamburger = "Nav-module--hamburger--om2vS";
export var icon = "Nav-module--icon--i7gTc";
export var instagram = "Nav-module--instagram--NWcai";
export var links = "Nav-module--links--1p43w";
export var logo = "Nav-module--logo--HxTTt";
export var open = "Nav-module--open--cQc8u";
export var paths = "Nav-module--paths--sfc9N";
export var transparent = "Nav-module--transparent--i-Wnt";
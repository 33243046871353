// extracted by mini-css-extract-plugin
export var active = "Contact-module--active--BTtqj";
export var active1 = "Contact-module--active-1--eDJqQ";
export var active10 = "Contact-module--active-10--MT92v";
export var active2 = "Contact-module--active-2--8zNRA";
export var active3 = "Contact-module--active-3--aRJjv";
export var active4 = "Contact-module--active-4--R2IiA";
export var active5 = "Contact-module--active-5--Kxi54";
export var active6 = "Contact-module--active-6--SrNkh";
export var active7 = "Contact-module--active-7--Gdwv-";
export var active8 = "Contact-module--active-8--EbjAL";
export var active9 = "Contact-module--active-9--GbVBn";
export var btns = "Contact-module--btns--d72i6";
export var contactBlock = "Contact-module--contact-block--aUDmJ";
export var core = "Contact-module--core--3TacH";
export var dropdown = "Contact-module--dropdown--XmwUF";
export var header = "Contact-module--header--5aaSe";
export var icon = "Contact-module--icon--DBGUH";
export var select = "Contact-module--select--NKKuf";
export var subpage = "Contact-module--subpage--xqfJW";
export var wrapper = "Contact-module--wrapper--1a6Wx";
// extracted by mini-css-extract-plugin
export var arrow = "Enterprise-module--arrow--OBTl+";
export var bar = "Enterprise-module--bar--9So3r";
export var bars = "Enterprise-module--bars--XlceY";
export var btn = "Enterprise-module--btn--3gmWI";
export var caption = "Enterprise-module--caption--6HabZ";
export var contact = "Enterprise-module--contact--jblQb";
export var description = "Enterprise-module--description--z9vag";
export var differentials = "Enterprise-module--differentials--UeiEw";
export var header = "Enterprise-module--header--d5Y5Z";
export var iframe = "Enterprise-module--iframe--4N6Fr";
export var info = "Enterprise-module--info--bZcfE";
export var location = "Enterprise-module--location--TYQ6y";
export var mail = "Enterprise-module--mail--zeY8R";
export var nav = "Enterprise-module--nav--EUX98";
export var nearBy = "Enterprise-module--near-by--s3ylJ";
export var progress = "Enterprise-module--progress--g-eBT";
export var project = "Enterprise-module--project--sNdB0";
export var slide = "Enterprise-module--slide--lkxgg";
export var slider = "Enterprise-module--slider--brznA";
export var sliderWrapper = "Enterprise-module--slider-wrapper--ZxPFK";
export var table = "Enterprise-module--table--baqln";
export var thumbnail = "Enterprise-module--thumbnail--Gc4lU";
export var tour = "Enterprise-module--tour--5fGxD";
export var value = "Enterprise-module--value--seeDn";
export var video = "Enterprise-module--video--aj2ij";
export var whatsapp = "Enterprise-module--whatsapp--4fG+J";
import React, { useEffect, useState } from 'react'
import { Logo, Link } from '~/components'
import { navItems, socials } from '~/data'
import cn from 'classnames'
import * as st from '~/assets/styl/Nav.module.styl'

type Props = {
  location: Location
}

const Nav = ({ location }: Props) => {
  const pathname = (location.pathname + '/').replace('//', '/')
  const [top, setTop] = useState(true)
  useEffect(() => {
    const onScroll = () => {
      setTop(window.scrollY === 0)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  })

  const [open, setOpen] = useState(false)

  return (
    <nav
      className={cn(
        st.core,
        top &&
          (pathname === '/' || pathname.startsWith('/empreendimento/')) &&
          st.transparent
      )}
    >
      <Link to="/" className={st.logo}>
        <Logo pathsClassName={st.paths} />
      </Link>
      <div className={cn(st.links, open && st.open)}>
        <ul>
          {navItems.map(({ name, url, external, icon, onClick }, key) => (
            <li key={key}>
              <Link
                to={url}
                external={external}
                className={cn(icon && st.icon)}
                onClick={(e) => {
                  setOpen(false)
                  onClick?.(e)
                }}
              >
                {icon && <span className={`icon-${icon}`}></span>}
                {name}
              </Link>
            </li>
          ))}
        </ul>
        <a
          href={socials.find(({ name }) => name === 'Instagram').url}
          target="_blank"
          className={cn(st.instagram, 'icon-instagram')}
          rel="noreferrer"
        ></a>
        <button className={st.close} onClick={() => setOpen(false)}></button>
      </div>
      <div className={st.hamburger}>
        <button onClick={() => setOpen(true)}></button>
      </div>
    </nav>
  )
}

export default Nav

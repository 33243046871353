import React, { useContext, useState, useEffect } from 'react'
import { context } from '~/layouts'
import { PageProps } from 'gatsby'
import {
  Head,
  Button,
  WideCarousel,
  Carousel,
  Map,
  Tabs,
  Lots,
  Modal,
  Lightbox,
  Footer,
  LazyLoad,
  Form,
} from '~/components'
//import { Link } from 'gatsby'
import { Context as EnterprisesContext } from '~/pages/_empreendimentos'
import { useApi } from '~/siteApi'
import cn from 'classnames'
import * as st from '~/assets/styl/Enterprise.module.styl'
import * as st1 from '~/assets/styl/Button.module.styl'
import * as st2 from '~/assets/styl/Form.module.styl'

function checkLatLng(lat: string, lng: string) {
  return !isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng))
}

function getVideoId(url: string) {
  const urlParts = url.split(/(youtu\.be\/)|(youtube\.com\/watch\?v=)/)
  return urlParts[urlParts.length - 1]
}

const ReadMore = ({ children }: { children: string }) => {
  const [excerpt, setExcerpt] = useState(
    children.replace(/\r\n/g, ' ').match(/^.{100}((?!\.).)*\./)?.[0] || children
  )

  return (
    <div>
      <p>{excerpt}</p>{' '}
      {excerpt !== children && (
        <button onClick={() => setExcerpt(children)}>Leia mais</button>
      )}
    </div>
  )
}

type Image = {
  urls: {
    original: string
    large: string
  }
  legend: string
}

type Context = {
  enterprise: {
    reference: string
    imageTop: string
    tag: string
    name: string
    neighborhood: string
    city: string
    state: string
    slogan: string
    imageLogo: string
    description: string
    metreageType: string
    lotType: string
    condominiumType: string
    book: string
    priceType: string
    priceValue: string
    priceCondition: string
    whatsapp: string
    imagesEnterprises: Array<Image>
    tourTitle: string
    tourContent: string
    tourEmbed: string
    videoTitle: string
    videoContent: string
    videoUrl: string
    locationTitle: string
    locationContent: string
    latitude: string
    longitude: string
    proximities: Array<{
      name: string
      value: string
    }>
    projectTitle: string
    projectContent: string
    imageProject: string
    imageZoom: string
    workTitle: string
    workContent: string
    deliveryPrevision: string
    stages: Array<{
      name: string
      value: string
    }>
    imagesWorks: Array<Image>
    videos: Array<{
      name: string
      url: string
    }>
    differentialTitle: string
    differentialContent: string
    differentials: Array<{
      name: string
      image: string
    }>
    film: boolean
  }
}

const Attendance = ({
  location,
  pageContext,
  slug,
}: PageProps & { slug?: string }) => {
  const { enterprise }: Context = useApi(pageContext, 'page-enterprise/' + slug)
  const {
    launchs,
    enterprises: _enterprises,
    portfolios,
  }: EnterprisesContext = useApi({}, 'page-enterprise')
  const enterprises = (launchs || [])
    .concat(_enterprises || [])
    .concat(portfolios || [])
    .filter(({ slug: thisSlug }) => slug !== thisSlug)

  // Zoom:
  const [images, setImages] = useState<Array<string>>()
  const [legends, setLegends] = useState<Array<string>>()
  const [imagesIndex, setImagesIndex] = useState<number>(0)
  const [videoUrl, setVideoUrl] = useState<string>(null)

  // Loading:
  const { setLoading } = useContext(context)
  useEffect(() => {
    setLoading(!enterprise?.name)
  }, [enterprise])

  // Formulário:
  const [formModal, setFormModal] = useState(false)

  return !enterprise ? (
    <></>
  ) : (
    <>
      <Head
        location={location}
        title={`${enterprise.name} - Atendimento Alínea`}
      />

      <header
        className={st.header}
        style={{
          backgroundImage:
            (enterprise.film
              ? 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.5) 100%), '
              : '') + `url(${enterprise.imageTop})`,
        }}
      >
        <h2>{enterprise.tag}</h2>
        <h1>{enterprise.name}</h1>
        <p>
          {enterprise.neighborhood} - {enterprise.city} - {enterprise.state}
        </p>
        <div className={cn(st1.arrow, st.arrow)}></div>
      </header>

      <section className={st.description}>
        <div className="container">
          <div>
            <div className={st.info}>
              <LazyLoad
                type="img"
                src={enterprise.imageLogo}
                width="200"
                height="200"
              />
              <h3>{enterprise.slogan}</h3>
              {enterprise.description && (
                <ReadMore>{enterprise.description}</ReadMore>
              )}
            </div>
            <div className={st.table}>
              <div>
                <span>Tamanhos de</span>
                <strong>{enterprise.metreageType}</strong>
              </div>
              <div>
                <span>Tipo de lote</span>
                <strong>{enterprise.lotType}</strong>
              </div>
              <div>
                <span>Tipo de Empreendimento</span>
                <strong>{enterprise.condominiumType}</strong>
              </div>
            </div>
            <div className={st.nav}>
              {enterprise.tourEmbed && (
                <Button to="#tour-virtual" scroll type="outlined tiny">
                  Tour virtual
                </Button>
              )}
              {enterprise.videoUrl && (
                <Button to="#video" scroll type="outlined tiny">
                  Vídeo
                </Button>
              )}
              {enterprise.imageProject && (
                <Button to="#projeto-urbanistico" scroll type="outlined tiny">
                  Projeto urbanístico
                </Button>
              )}
              {Boolean(enterprise.stages?.length) && (
                <Button to="#obra" scroll type="outlined tiny">
                  Acompanhe a obra
                </Button>
              )}
              {enterprise.book && (
                <Button to={enterprise.book} download type="outlined tiny">
                  Download book
                </Button>
              )}
              <Button to="#localizacao" scroll type="outlined tiny">
                Localização
              </Button>
            </div>
          </div>
          <div>
            {Boolean(enterprise.priceType && enterprise.priceValue) && (
              <div className={st.value}>
                <span>{enterprise.priceType}</span>
                <strong>{enterprise.priceValue}</strong>
                <span>{enterprise.priceCondition}</span>
              </div>
            )}
            <div className={st.contact}>
              <Button
                type="noArrow"
                className={st.btn}
                onClick={() => setFormModal(true)}
              >
                <div>
                  <span className="icon-mail"></span>Fale com um consultor
                </div>
              </Button>
              {enterprise.whatsapp && (
                <Button
                  to={`https://api.whatsapp.com/send?phone=55${enterprise.whatsapp.replace(
                    /\D/g,
                    ''
                  )}`}
                  external
                  type="noArrow"
                  className={cn(st.btn, st.whatsapp)}
                >
                  <div>
                    <span className="icon-whatsapp"></span>Chame agora no
                    WhatsApp
                  </div>
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className={st.sliderWrapper}>
        <WideCarousel className={st.slider}>
          {enterprise.imagesEnterprises.map(({ urls, legend }, index) => (
            <LazyLoad
              className={st.slide}
              src={urls.large}
              key={index}
              onClick={() => {
                setImagesIndex(index)
                setLegends(enterprise.imagesEnterprises.map((i) => i.legend))
                setImages(
                  enterprise.imagesEnterprises.map((i) => i.urls.original)
                )
              }}
            >
              <span className={st.caption}>{legend}</span>
            </LazyLoad>
          ))}
        </WideCarousel>
      </section>

      {enterprise.tourEmbed && (
        <section
          className={st.tour}
          id="tour-virtual"
          style={{ paddingBottom: 0 }}
        >
          <div className="container">
            <h3>{enterprise.tourTitle}</h3>
            <p>{enterprise.tourContent}</p>
            <div
              className={st.iframe}
              dangerouslySetInnerHTML={{ __html: enterprise.tourEmbed }}
            ></div>
          </div>
        </section>
      )}

      {enterprise.videoUrl && (
        <section
          className={cn(st.tour, st.video)}
          id="video"
          style={{ paddingBottom: 0 }}
        >
          <div className="container">
            <h3>{enterprise.videoTitle}</h3>
            <p>{enterprise.videoContent}</p>
            <div className={st.iframe}>
              <iframe
                src={`https://www.youtube.com/embed/${getVideoId(
                  enterprise.videoUrl
                )}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </section>
      )}

      <section
        className={st.location}
        id="localizacao"
        style={enterprise.imageProject ? { paddingBottom: 0 } : null}
      >
        <div className="container">
          <h3>{enterprise.locationTitle}</h3>
          <p>{enterprise.locationContent}</p>
          {checkLatLng(enterprise.latitude, enterprise.longitude) && (
            <Map
              location={{
                lat: parseFloat(enterprise.latitude),
                lng: parseFloat(enterprise.longitude),
              }}
              pin={enterprise.imageLogo}
            />
          )}
          <ul className={st.nearBy}>
            {enterprise.proximities.map(({ name, value }, key) => (
              <li key={key}>
                <strong>{name}</strong>
                <span></span>
                <span>{value}</span>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {enterprise.imageProject && (
        <section className={st.project} id="projeto-urbanistico">
          <div className="container">
            <h3>{enterprise.projectTitle}</h3>
            <p>{enterprise.projectContent}</p>
            <LazyLoad
              type="img"
              src={enterprise.imageProject}
              width="100%"
              onClick={() => setImages([enterprise.imageZoom])}
            />
            <div className={st.contact}>
              <Button
                type="noArrow"
                className={cn(st.btn, st.mail)}
                onClick={() => setFormModal(true)}
              >
                <div>
                  <span className="icon-mail"></span>Fale com um consultor
                </div>
              </Button>
              {enterprise.whatsapp && (
                <Button
                  to={`https://api.whatsapp.com/send?phone=55${enterprise.whatsapp.replace(
                    /\D/g,
                    ''
                  )}`}
                  external
                  type="noArrow"
                  className={cn(st.btn, st.whatsapp)}
                >
                  <div>
                    <span className="icon-whatsapp"></span>Chame agora no
                    WhatsApp
                  </div>
                </Button>
              )}
            </div>
          </div>
        </section>
      )}

      <section className={st.differentials}>
        <div className="container">
          <h3>{enterprise.differentialTitle}</h3>
          <p>{enterprise.differentialContent}</p>
          <Carousel
            className={st.slider}
            slidesToShow={5}
            slidesToShowMobile={2}
            matchMedia={700}
          >
            {enterprise.differentials
              .slice(0, Math.ceil(enterprise.differentials.length / 2))
              .map((_, key) => (
                <div className={st.slide} key={key}>
                  <LazyLoad
                    type="img"
                    src={enterprise.differentials[2 * key].image}
                    width="86"
                  />
                  <p>{enterprise.differentials[2 * key].name}</p>
                  {enterprise.differentials[2 * key + 1] && (
                    <>
                      <LazyLoad
                        type="img"
                        src={enterprise.differentials[2 * key + 1].image}
                        width="86"
                      />
                      <p>{enterprise.differentials[2 * key + 1].name}</p>
                    </>
                  )}
                </div>
              ))}
          </Carousel>
        </div>
      </section>

      {Boolean(enterprise.stages?.length) && (
        <section className={st.progress} id="obra">
          <div className="container">
            <h3>{enterprise.workTitle}</h3>
            <p>{enterprise.workContent}</p>
            <div className={st.bar}>
              <p>{enterprise.deliveryPrevision}</p>
              <div>
                <div style={{ width: `${enterprise.stages[0].value}%` }}>
                  {enterprise.stages[0].value}%
                </div>
              </div>
              <p>{enterprise.stages[0].name}</p>
            </div>
            <ul className={st.bars}>
              {enterprise.stages.slice(1).map(({ name, value }, key) => (
                <li className={st.bar} key={key}>
                  <p>{name}</p>
                  <div>
                    <div style={{ width: `${value}%` }}>{value}%</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {Boolean(
            enterprise.imagesWorks.length || enterprise.videos.length
          ) && (
            <Tabs
              items={['Fotos da obra', 'Vídeos da obra']}
              default={
                !enterprise.imagesWorks.length && enterprise.videos.length
                  ? 1
                  : 0
              }
            >
              <div className="container">
                {!enterprise.imagesWorks.length ? (
                  <p>Nenhuma foto de obra encontrada.</p>
                ) : (
                  <Carousel>
                    {enterprise.imagesWorks.map(({ urls, legend }, index) => (
                      <div
                        className={st.slide}
                        key={index}
                        onClick={() => {
                          setImagesIndex(index)
                          setLegends(
                            enterprise.imagesWorks.map((i) => i.legend)
                          )
                          setImages(
                            enterprise.imagesWorks.map((i) => i.urls.original)
                          )
                        }}
                      >
                        <LazyLoad className={st.thumbnail} src={urls.large} />
                        <p>{legend}</p>
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
              <div className="container">
                {!enterprise.videos.length ? (
                  <p>Nenhum vídeo de obra encontrado.</p>
                ) : (
                  <Carousel>
                    {enterprise.videos.map(({ name, url }, key) => (
                      <div
                        className={cn(st.slide, st.video)}
                        key={key}
                        onClick={() => setVideoUrl(url)}
                      >
                        <LazyLoad
                          className={st.thumbnail}
                          src={`https://img.youtube.com/vi/${getVideoId(
                            url
                          )}/0.jpg`}
                        />
                        <p>{name}</p>
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            </Tabs>
          )}
        </section>
      )}

      {Boolean(enterprises?.length) && (
        <section
          style={
            enterprise.imagesWorks.length || enterprise.videos.length
              ? { paddingTop: 0 }
              : null
          }
        >
          <div className="container">
            <h3>Conheça outros empreendimentos Alínea</h3>
            <p>Terrenos, loteamentos e lotes</p>
            <Lots items={enterprises.slice(0, 9)} slider />
          </div>
        </section>
      )}

      <Footer />

      <Modal
        open={Boolean(videoUrl)}
        onClose={() => setVideoUrl(null)}
        className="video-modal"
      >
        <iframe
          width="560"
          height="315"
          src={
            videoUrl && `https://www.youtube.com/embed/${getVideoId(videoUrl)}`
          }
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal>

      <Lightbox
        index={imagesIndex}
        setIndex={setImagesIndex}
        images={images}
        setImages={setImages}
        legends={legends}
        setLegends={setLegends}
      />

      <Modal
        open={formModal}
        onClose={() => setFormModal(false)}
        className="form-modal"
      >
        <h3>Fale com um consultor</h3>
        <p>
          Precisando de informações sobre nosso loteamento?
          <br />
          Utilize o canal abaixo para solicitar um contato.
        </p>
        <Form
          path="page-enterprise"
          idPrefix="consultor"
          className={st2.form}
          hiddenInputs={{
            enterprise: enterprise.name,
          }}
          inputs={[
            {
              name: 'name',
              label: 'Nome',
              className: st2.wide,
            },
            {
              name: 'email',
              label: 'E-mail',
              type: 'email',
              className: st2.medium,
            },
            {
              name: 'phone',
              label: 'Telefone',
              mask: 'phone',
              placeholder: '(DD) + Número',
              className: st2.medium,
            },
            {
              name: 'message',
              label: 'Mensagem',
              type: 'textarea',
              className: st2.wide,
            },
            /*{
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link to="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: st2.privacy,
            },*/
          ]}
          button={<Button>Enviar</Button>}
          loadingButton={<Button disabled>Enviando...</Button>}
        />
      </Modal>
    </>
  )
}

export default Attendance

import { MouseEventHandler } from 'react'
import IconEmail from '~/assets/svg/Email.svg'
import IconPresident from '~/assets/svg/Presidente.svg'
import IconRate from '~/assets/svg/Avaliar.svg'
import IconArea from '~/assets/svg/Area.svg'
import IconPartner from '~/assets/svg/Parceria.svg'
import IconBroker from '~/assets/svg/Corretor.svg'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const windowGlobal: any = typeof window !== 'undefined' ? window : {}

type NavItem = {
  name: string
  url: string
  external?: boolean
  icon?: string
  onClick?: MouseEventHandler
  subItems?: Array<{
    name: string
    url: string
    icon?: string
    page?: string | boolean
    scrollTo?: string
  }>
}

const navItems: Array<NavItem> = [
  { name: 'Empresa', url: '/empresa/' },
  {
    name: 'Empreendimentos',
    url: '/empreendimentos/',
    subItems: [
      { name: 'Lançamentos', url: '/empreendimentos/' },
      {
        name: 'Terrenos, loteamentos e lotes',
        url: '/empreendimentos/#Empreendimentos',
        scrollTo: 'Empreendimentos',
      },
      // {
      //   name: 'Portfólio',
      //   url: '/empreendimentos/#Portfolio',
      //   scrollTo: 'Portfolio',
      // },
    ],
  },
  { name: 'Blog', url: 'https://blog.alineaurbanismo.com.br', external: true },
  {
    name: 'Atendimento',
    url: '/atendimento/',
    subItems: [
      { name: 'Fale conosco', url: '/contato/', icon: IconEmail },
      { name: 'Ouvidoria', url: 'tel:4833817000', page: false },
      {
        name: 'Fale com o presidente',
        url: '/fale-com-o-presidente/',
        icon: IconPresident,
      },
      {
        name: 'Avalie seu atendimento',
        url: '/avalie-seu-atendimento/',
        icon: IconRate,
      },
      {
        name: 'Trabalhe conosco',
        url: 'https://alineavagas.solides.jobs',
        page: 'external',
      },
      { name: 'Ofereça sua área', url: '/ofereca-sua-area/', icon: IconArea },
      {
        name: 'Seja um parceiro',
        url: '/seja-um-parceiro/',
        icon: IconPartner,
      },
      { name: 'Seja um corretor', url: '/seja-um-corretor/', icon: IconBroker },
    ],
  },
  { name: 'Portal do cliente', url: '/portal-do-cliente/', icon: 'user' },
  { name: 'Ofereça sua área', url: '/ofereca-sua-area/', icon: 'pin' },
  {
    name: '2ª via do boleto',
    url: '/2-via-boleto/',
    icon: 'boleto',
    onClick: () => windowGlobal.ViaBoleto?.(),
  },
]

export default navItems

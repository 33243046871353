import { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import api from '.'

const useApi = (defaultValue, path) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    api('get', path)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(({ code, message, ...data }) => setValue(data))
      .catch(() => navigate('/404'))
  }, [])

  return value
}

export default useApi

/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useState } from 'react'
import { LazyImageFull, ImageState } from 'react-lazy-images'
import { Link } from '~/components'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'
import Spinner from '~/assets/svg/Loading-spinner.svg'

const LazyLoadWrapper = forwardRef(
  (
    {
      imageState,
      src,
      className,
      children,
      type,
      url,
      external,
      film,
      ...props
    },
    ref
  ) => {
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
      if (imageState === ImageState.LoadSuccess)
        setTimeout(() => setLoaded(true), 500)
    }, [imageState])

    return url ? (
      <Link
        ref={ref}
        to={url}
        external={external}
        className={cn(className, !loaded && 'lazy-loading')}
        style={lazyStyle(imageState, src, film)}
        {...props}
      >
        {children}
      </Link>
    ) : type === 'img' ? (
      <img
        ref={ref}
        className={className}
        src={imageState === ImageState.LoadSuccess ? src : ''}
        {...props}
      />
    ) : type === 'li' ? (
      <li
        ref={ref}
        className={cn(className, !loaded && 'lazy-loading')}
        style={lazyStyle(imageState, src, film)}
        {...props}
      >
        {children}
      </li>
    ) : (
      <div
        ref={ref}
        className={cn(className, !loaded && 'lazy-loading')}
        style={lazyStyle(imageState, src, film)}
        {...props}
      >
        {children}
      </div>
    )
  }
)
LazyLoadWrapper.displayName = 'LazyLoadWrapper'

function lazyStyle(imageState, src, film) {
  if (imageState === ImageState.LoadSuccess && film)
    return {
      backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.5) 100%), url(${src})`,
    }
  else if (imageState === ImageState.LoadSuccess)
    return {
      backgroundImage: `url(${src})`,
    }
  return {
    background: `url(${Spinner}) no-repeat center / 50px`,
  }
}

const LazyLoad = (props) => {
  const { src: _src, mobileSrc } = props
  const wide = useMediaQuery({
    query: '(min-width: 701px)',
  })
  const src = wide ? _src : mobileSrc || _src

  return (
    <LazyImageFull src={src}>
      {({ imageState, ref }) => (
        <LazyLoadWrapper
          {...props}
          src={src}
          ref={ref}
          imageState={imageState}
        />
      )}
    </LazyImageFull>
  )
}

export default LazyLoad
